import React from "react"
import { Themed } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      都内でWebエンジニアをやっています。妻と子二人と慌ただしく生活しています。
      <br />
      Ruby,Rails,golang,React,Vue,AWSなど幅広く携わっています。
    </>
  )
}
